import axios from "axios";
import { isNil, isEmpty } from "lodash";
import { DESIGN_API_URL } from "../../lib/environment.js";

import { newAbortSignalFunc } from "../api";
import { escapeRegExp } from '../utils';

const headlines = () => {
	let headlinesCancelToken = axios.CancelToken.source();
	let timeoutAmount = 180000; // Aborts request after 3 minutes

  return {
		cancel: () => {
			headlinesCancelToken.cancel('Operation canceled by the user');
			headlinesCancelToken = axios.CancelToken.source();
		},
    create: (data) => {
      return new Promise((resolve, reject) => {
        const controller = new AbortController();

        let timeout = setTimeout(() => {
          controller.abort();
        }, 180000);

        // Ensure the variant is set to 'headline'
        const headlineData = {
          ...data,
          variant: 'headline'
        };

        axios({
          url: `${DESIGN_API_URL}/templates`,
          method: "POST",
          cache: "no-cache",
          data: headlineData,
          signal: controller.signal,
        })
          .then((resp) => {
            clearTimeout(timeout);
            resolve(resp.data.result);
          })
          .catch((err) => {
            clearTimeout(timeout);
            reject(err);
          });
      });
    },

    read: (headlineId = null, queryParams = {}, limit = 24, offset = 0) => {
      return new Promise((resolve, reject) => {
        const controller = new AbortController();

        let timeout = setTimeout(() => {
          controller.abort();
        }, 180000);

        // Always add headline variant to query parameters
        const headlineQueryParams = {
					variant: 'headline',
					'owner.idNumber': 0,
					status: 'active',
					isHidden: false
        };

        const params = new URLSearchParams({
          q: JSON.stringify(headlineQueryParams),
          limit,
          offset,
          sort: JSON.stringify(headlineQueryParams?.name ? {"isProprietary":"desc", "name":"asc"} : {"dtUpd":"desc"})
        });

        if ((isNil(headlineId) || isEmpty(headlineId))) {
          axios({
            url: `${DESIGN_API_URL}/templates/?${params.toString()}`,
            method: "GET",
            cache: "no-cache",
            signal: newAbortSignalFunc(timeoutAmount),
            cancelToken: headlinesCancelToken.token
          })
            .then((resp) => {
              clearTimeout(timeout);
              if (resp.data.status === 0) {
                resolve(resp.data);
              } else {
                reject(resp.data);
              }
            })
            .catch((err) => {
              clearTimeout(timeout);
              reject(err);
            });
        } else {
          axios({
            url: `${DESIGN_API_URL}/templates/${headlineId}`,
            method: "GET",
            cache: "no-cache",
            signal: newAbortSignalFunc(timeoutAmount),
            cancelToken: headlinesCancelToken.token
          })
            .then((resp) => {
              clearTimeout(timeout);
              if (resp.data.status === 0) {
                // Verify it's a headline variant
                if (resp.data.result.variant !== 'headline') {
                  reject(new Error("Requested template is not a headline"));
                  return;
                }
                resolve(resp.data.result);
              } else {
                reject(resp.data);
              }
            })
            .catch((err) => {
              clearTimeout(timeout);
              reject(err);
            });
        }
      });
    },

    update: (headlineId, headlineData) => {
      return new Promise((resolve, reject) => {
        const controller = new AbortController();

        let timeout = setTimeout(() => {
          controller.abort();
        }, 180000);

        // Ensure variant remains 'headline'
        const updatedData = {
          ...headlineData,
          variant: 'headline'
        };

        axios({
          url: `${DESIGN_API_URL}/templates/${headlineId}`,
          method: "PATCH",
          cache: "no-cache",
          data: updatedData,
          signal: controller.signal,
        })
          .then((resp) => {
            clearTimeout(timeout);
            resolve(resp.data.result);
          })
          .catch((err) => {
            clearTimeout(timeout);
            reject(err);
          });
      });
    },

    delete: (headlineId) => {
      return new Promise((resolve, reject) => {
        const controller = new AbortController();

        let timeout = setTimeout(() => {
          controller.abort();
        }, 180000);

        axios({
          url: `${DESIGN_API_URL}/templates/${headlineId}`,
          method: "DELETE",
          cache: "no-cache",
          signal: controller.signal,
        })
          .then((resp) => {
            clearTimeout(timeout);
            if (resp.data.status === 0) {
              resolve(resp.data.result);
            } else {
              reject(resp.data);
            }
          })
          .catch((err) => {
            clearTimeout(timeout);
            reject(err);
          });
      });
    },

    // Custom read for handling pagination with next URLs
    customRead: (url) => {
      return new Promise((resolve, reject) => {
        const controller = new AbortController();

        let timeout = setTimeout(() => {
          controller.abort();
        }, 180000);

        axios({
          url: url,
          method: "GET",
          cache: "no-cache",
          signal: controller.signal,
          cancelToken: headlinesCancelToken.token
        })
          .then((resp) => {
            clearTimeout(timeout);
            if (resp.data.status === 0) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }
          })
          .catch((err) => {
            clearTimeout(timeout);
            reject(err);
          });
      });
    },

    setVisibility: (headlineId, visibility, userKey) => {
      return new Promise((resolve, reject) => {
        const controller = new AbortController();

        let timeout = setTimeout(() => {
          controller.abort();
        }, 180000);

        if (!userKey) {
          reject("User key is required");
          return;
        }

        axios({
          url: `${DESIGN_API_URL}/templates/${headlineId}`,
          method: "PATCH",
          data: {
            [`prefsOf.customers.${userKey}.hide`]: visibility,
          },
          cache: "no-cache",
          signal: controller.signal,
        })
          .then((resp) => {
            clearTimeout(timeout);
            if (resp.data.status === 0) {
              resolve(resp.data);
            } else {
              reject(resp.data);
            }
          })
          .catch((err) => {
            clearTimeout(timeout);
            reject(err);
          });
      });
    }
  };
};

export default headlines();
